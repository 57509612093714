import hljs from "highlight.js";
import bash from "highlight.js/lib/languages/bash";
import yaml from "highlight.js/lib/languages/yaml";
import diff from "highlight.js/lib/languages/diff";
import go from "highlight.js/lib/languages/go";

import "highlight.js/styles/default.css";
import "highlight.js/styles/github.css";

export default function initSyntaxHighlighting() {
  hljs.registerLanguage("bash", bash);
  hljs.registerLanguage("yaml", yaml);
  hljs.registerLanguage("diff", diff);
  hljs.registerLanguage("go", go);
}
