import React, { useState, useEffect } from "react";
import axios from "axios";

import LoadingMessage from "../LoadingMessage";
import ErrorMessage from "../ErrorMessage";
import MarkdownRenderer from "../MarkdownRenderer";

type MarkdownLoaderProps = {
  url: string;
};

const MarkdownLoader = ({ url }: MarkdownLoaderProps) => {
  const [markdown, setMarkdown] = useState("");
  const [markdownError, setMarkdownError] = useState("");

  useEffect(() => {
    let cancelled = false;
    const fetchData = async () => {
      try {
        const { data } = await axios.get(url);
        if (!cancelled) {
          setMarkdown(data);
        }
      } catch (error: any) {
        if (!cancelled) {
          setMarkdownError(error.message);
        }
      }
    };

    fetchData();

    return () => {
      cancelled = true;
    };
  }, [url]);

  if (markdownError) {
    return (
      <ErrorMessage message={`Error loading help content: ${markdownError}`} />
    );
  }

  if (markdown) {
    return <MarkdownRenderer markdown={markdown} />;
  }

  return <LoadingMessage label="Loading help content" />;
};

export default MarkdownLoader;
