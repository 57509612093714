import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";

type FlashProps = {
  message: string;
  id: string;
};

const Flash = ({ message, id }: FlashProps) => {

  const handleDismiss = () => {
  //    TODO (JT 20221017): Re-implement dismissing flashes
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open
      ContentProps={{
        "aria-describedby": id
      }}
    >
      <SnackbarContent
        id={id}
        message={message}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleDismiss}
          >
            <CloseIcon />
          </IconButton>
        ]}
      ></SnackbarContent>
    </Snackbar>
  );
};

const Flashes = () => {
  const flashes: Array<any> = []; // TODO (JT 20221017): Re-implement flashes

  return (
    <>
      {flashes.map(flash => (
        <Flash key={flash.id} {...flash} />
      ))}
    </>
  );
};

export default Flashes;
