import React from "react";
import { Link, NavLink } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { COURSES_PATH } from "../../constants";

import "./WorkshopNav.css";
import { Course } from "../../typings";
import { Workshop } from "../../typings/Workshop";

type WorkshopNavProps = {
  course: Course;
  workshopNdx: number;
  showToggle?: boolean;
  toggleAction: Function;
};

const buildWorkshopPath = (workshopNdx: number, courseId: string) => {
  return `${COURSES_PATH}/${courseId}/${workshopNdx}`;
};

const WorkshopNav = ({
  course,
  workshopNdx,
  showToggle = true,
  toggleAction
}: WorkshopNavProps) => {
  const courseId: string = course?.name;
  const workshops: Workshop[] = course?.workshops;

  const handleShowDescriptionClick = () => {
    toggleAction();
  };

  const renderNav = () => {
    if (!course) return null;

    const previousPath = () => {
      if (workshopNdx < 1) return "";
      return buildWorkshopPath(workshopNdx - 1, courseId);
    };

    const nextPath = () => {
      if (workshopNdx >= workshops.length - 1) return "";
      return buildWorkshopPath(workshopNdx + 1, courseId);
    };

    return (
      <div className="WorkshopNav__container">
        <div className="WorkshopNav__top">
          <Tooltip
            title={`Back to ${course.displayName || course.name}`}
            placement="right"
          >
            <Link
              to={`${COURSES_PATH}/${courseId}`}
              className="WorkshopNav__back"
            >
              <ArrowBackIcon />
            </Link>
          </Tooltip>
          {showToggle && (
            <Tooltip
              title={`Toggle Description / Table of contents`}
              placement="right"
            >
              <button
                className="WorkshopNav__button"
                onClick={handleShowDescriptionClick}
              >
                <ListAltIcon />
              </button>
            </Tooltip>
          )}
        </div>

        {workshops && (
          <ul className="WorkshopNav__list">
            <li
              className={
                workshopNdx < 1
                  ? "WorkshopNav__listItem WorkshopNav__listItem--hidden"
                  : "WorkshopNav__listItem"
              }
            >
              <Tooltip title={`Previous Workshop`} placement="right">
                <Link to={previousPath()} className="WorkshopNav__back">
                  <KeyboardArrowUpIcon />
                </Link>
              </Tooltip>
            </li>

            {workshops.map((workshop, ndx) => {
              return (
                <li key={ndx}>
                  <Tooltip title={workshop.displayName} placement="right">
                    <span>
                      <NavLink
                        to={buildWorkshopPath(ndx, courseId)}
                        className="WorkshopNav__navLink"
                        activeClassName="WorkshopNav__navLink--active"
                      >
                        <FiberManualRecordIcon fontSize="inherit" />
                      </NavLink>
                    </span>
                  </Tooltip>
                </li>
              );
            })}
            <li
              className={
                workshopNdx >= workshops.length - 1
                  ? "WorkshopNav__listItem WorkshopNav__listItem--hidden"
                  : "WorkshopNav__listItem"
              }
            >
              <Tooltip title={`Next Workshop`} placement="right">
                <Link to={nextPath()} className="WorkshopNav__back">
                  <KeyboardArrowDownIcon />
                </Link>
              </Tooltip>
            </li>
          </ul>
        )}
      </div>
    );
  };

  return <nav className="WorkshopNav">{renderNav()}</nav>;
};

export default WorkshopNav;
