import React from "react";

import Page from "../Page";
import HomeContent from "../HomeContent";
import "./Home.css";

const Home = () => {
    return (
        <Page title="Home" className="Home">
            <HomeContent/>
        </Page>
    );
};

export default Home;
