import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import {createBrowserHistory, LocationState} from "history";
import {ConnectedRouter, connectRouter, RouterState} from "connected-react-router";

import initSyntaxHighlighting from "./utils/initSyntaxHighlighting";
import initAnalytics from "./utils/initAnalytics";

import App from "./App";

import "./reset.css";
import "./theme.css";
import "./index.css";
import {configureStore, Reducer} from "@reduxjs/toolkit";
import {Auth0Provider} from "@auth0/auth0-react";
import { Toaster } from "react-hot-toast";

declare global {}

// Initialise Google Analytics
initAnalytics();
// Intialise Sentry Error Tracking
// initSentry();
// Initialise Syntax highlighting using highlight.js
initSyntaxHighlighting();

const auth0Domain: string = process.env["REACT_APP_AUTH0_DOMAIN"] ? process.env["REACT_APP_AUTH0_DOMAIN"] : ""
const auth0ClientId: string = process.env["REACT_APP_AUTH0_CLIENTID"] ? process.env["REACT_APP_AUTH0_CLIENTID"] : ""
const auth0Redirect: string = process.env["REACT_APP_AUTH0_REDIRECTURI"] ? process.env["REACT_APP_AUTH0_REDIRECTURI"] : ""
const auth0Audience: string = process.env["REACT_APP_AUTH0_AUDIENCE"] ? process.env["REACT_APP_AUTH0_AUDIENCE"] : ""
const history = createBrowserHistory();
createSagaMiddleware();

const store = configureStore({
    reducer: {
        router: connectRouter(history) as any as Reducer<RouterState<LocationState>>,
    }
})

// @ts-ignore
ReactDOM.render(
  <Provider store={store}>
      <Auth0Provider domain={auth0Domain} clientId={auth0ClientId} redirectUri={auth0Redirect} audience={auth0Audience}>
        <ConnectedRouter history={history}>
            <Toaster/>
            <App />
        </ConnectedRouter>
      </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);
