import { useEffect } from "react";

const useDocumentTitle = (title?: string) => {
  useEffect(() => {
    // Set the browser window title
    window.document.title = "Jetstack | Flightdeck";
  }, [title]);
};

export default useDocumentTitle;
