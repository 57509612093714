import {sec} from "./security";
import YAML from "yaml";

export async function getAndParseCourses() {
    const token: string = await sec.getAccessTokenSilently()();
    const response: Response = await fetch(`${process.env.REACT_APP_FLIGHTDECK_API}/`, {headers: {"Authorization": `Bearer ${token}`}});
    if (!response.ok) {
        throw new Error('response was not OK');
    }
    return response.json();
}

export async function getAndParseCourseDetails(courseId: string) {
    const token: string = await sec.getAccessTokenSilently()();
    const response: Response = await fetch(`${process.env.REACT_APP_FLIGHTDECK_API}/${courseId}/course.yaml`, {headers: {"Authorization": `Bearer ${token}`}});
    if (!response.ok) {
        throw new Error('response was not OK');
    }
    // console.log(`got course data for ${courseId}`);
    return response.text().then((text) => YAML.parse(text));
}

export const courseDetailQueries = (courseList: Array<string>) =>
    (courseList.map((course: string) => (
        {
            queryKey: ['course', course],
            queryFn: () => getAndParseCourseDetails(course),
            options: {
                enabled: courseList.length > 0
            },
        })));