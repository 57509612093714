import isDevEnv from "./isDevEnv";

/* eslint-disable */
const analytics_id = process.env.REACT_APP_ANALYTICS_ID || "tracking_id";

export default function initAnalytics() {
    if (!isDevEnv()) {
        // prettier-ignore
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

        window.ga("create", analytics_id, "auto");
    }
}
