import React from "react";
import { Link } from "react-router-dom";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";

import "./HomeContent.css";

const HomeContent = () => (
  <div className="HomeContent">
    <div className="HomeContent__content">
      <div className="HomeContent__copy">
        <h1 className="HomeContent__title">Welcome to Flightdeck</h1>
        <h2 className="HomeContent__subtitle">
          Jetstack&lsquo;s online training platform
        </h2>
        <p>
          Our hands-on modular courses will train you in deploying Kubernetes
          and operating it efficiently, whatever your level of expertise.
        </p>
        <p>
          To get started, click on the &ldquo;Go to Courses&ldquo; button below
          and follow the Workshops.
        </p>
        <p>
          <span>For more information on our training </span>
          <span>and services please visit </span>
          <a
            href="https://jetstack.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.jetstack.io
          </a>
          <span> or email us on </span>
          <a href="mailto:hello@jetstack.io">hello@jetstack.io</a>
        </p>
      </div>
      <div className="HomeContent__button">
        <Link to="/courses" className="Button">
          <LibraryBooksOutlinedIcon />
          <span className="HomeContent__buttonText">Go to Courses</span>
        </Link>
      </div>
    </div>
  </div>
);

export default HomeContent;
