import "./OfflineWarning.css";

import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import { TransitionProps } from "@material-ui/core/transitions";

const TransitionComponent = (props: TransitionProps) => (
  <Slide {...props} direction="down" />
);

const OfflineWarning = () => {
  const online = true; // TODO (JT 20221017): Re-implement offline warning

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={!online}
      className="OfflineWarning"
      ContentProps={{
        "aria-describedby": "offline-warning",
        className: "OfflineWarning__container"
      }}
      message={
        <span className="OfflineWarning__content" id="offline-warning">
          <OfflineBoltIcon />
          <span>
            <strong>You appear to be offline.</strong> <br /> You may experience
            issues loading content and connecting to interactive sandbox
            environments if you continue offline. Please restore your
            connection.
          </span>
        </span>
      }
      TransitionComponent={TransitionComponent}
    />
  );
};

export default OfflineWarning;
