import React from "react";

import logoImage from "./jetstack-logo-negative.svg";

const Logo = () => (
  <div className="Login__logo">
    <img src={logoImage} alt="" className="Login__logoImage" />
    <svg width="350" height="125" viewBox="0 0 350 125">
      <text
        x="40"
        y="77"
        fontFamily="sofia-pro"
        fontSize="50px"
        fontWeight="600"
        fill="white"
      >
        FLIGHTDECK
      </text>
    </svg>
  </div>
);

export default Logo;
