import React from "react";
import Grid from "@material-ui/core/Grid";
import { NavLink, Redirect, Route } from "react-router-dom";

import Page from "../Page";
import { HELP_PATH } from "../../constants";
import NotFound from "../NotFound";
import MarkdownLoader from "../MarkdownLoader";

import "./Help.css";

// From this array the Help pages table of contents and routes are set up.
// Adding a `require('../path/to/file.md') to the file property will ensure
// Webpack copies the content over to the media folder at build time
const contents = [
  {
    title: "Time Limited Access",
    slug: "time-limited-access",
    file: require("../../content/help/time-limited-access.md")
  },
  {
    title: "Supported Web Browsers",
    slug: "supported-browsers",
    file: require("../../content/help/supported-browsers.md")
  },
  {
    title: "Sign In and Authentication",
    slug: "authentication",
    file: require("../../content/help/authentication.md")
  },
  {
    title: "Reporting a Problem",
    slug: "reporting-a-problem",
    file: require("../../content/help/reporting-a-problem.md")
  }
];

const Help = () => {
  return (
    <Page title="Help">
      <Grid item xs={3} className="Help__nav">
        <h1 className="Help__navTitle">Help</h1>

        <nav>
          {contents.map(route => (
            <div key={route.slug}>
              <NavLink
                to={`${HELP_PATH}/${route.slug}`}
                className="Help__link"
                activeClassName="Help__link--active"
              >
                {route.title}
              </NavLink>
            </div>
          ))}
        </nav>

        <div className="Help__version">
          <h6 className="Help__versionTitle">Version</h6>
          <p>Commit: {process.env.REACT_APP_CI_COMMIT_SHA}</p>
          <p>Pipeline ID: {process.env.REACT_APP_CI_PIPELINE_ID}</p>
        </div>
      </Grid>

      <Grid item xs={9}>
        <article className="Help__content">
          {/* Redirect user to first help article if they hit the `/help` route  */}
          <Route
            path={HELP_PATH}
            exact
            render={() => <Redirect to={`${HELP_PATH}/${contents[0].slug}`} />}
          />
          <Route
            path={`${HELP_PATH}/:routeSlug`}
            render={props => {
              const route = contents.find(
                // eslint-disable-next-line react/prop-types
                content => content.slug === props.match.params.routeSlug
              );

              if (route)
                return <MarkdownLoader url={route.file} key={route.file} />;

              return <NotFound />;
            }}
          />
        </article>
      </Grid>
    </Page>
  );
};

export default Help;
