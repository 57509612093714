import React, { useState } from "react";
import { Workshop } from "../../typings/Workshop";
import Button from "../Button";
import "./WorkshopCollapsibleArea.css";

type WorkshopCollapsibleAreaProps = {
    workshop: Workshop;
};

const WorkshopCollapsibleArea = ({ workshop }: WorkshopCollapsibleAreaProps) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <div className="WorkshopCollapsibleArea" style={{ width: isVisible ? '25%' : '0px', padding: isVisible ? '7rem 30px 2rem' : '0px' }}>
            <div className="buttonWrapper">
                <Button onClick={() => setIsVisible(!isVisible)}>
                    {isVisible ? 'Expand Workshop Instructions' : 'Collapse Workshop Instructions'}
                </Button>
            </div>
            {isVisible && (
                <>
                    <h2>
                        {workshop.displayName}
                    </h2>
                    <p>
                        {workshop.description}
                    </p>
                </>
            )}
        </div>
    );
};

export default WorkshopCollapsibleArea;
