export const HOME_PATH = "/";
export const COURSES_PATH = "/courses";
export const HELP_PATH = "/help";
export const LOGIN_PATH = "/login";
export const AUTH_CALLBACK_PATH = "/auth/callback";
export const ERROR_PATH = "/error";

export const HUMAN_READABLE_TIME_FORMAT = "HH:mm - do MMM yyyy";




