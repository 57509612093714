import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiChipsInput } from 'mui-chips-input'
import { Course } from "../../typings";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { green, red } from "@material-ui/core/colors";
import { FormControl } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import YAML from 'yaml';
import toast from 'react-hot-toast';

import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

enum InviteRequestStatusType {
  Inactive = 1,
  Fetching,
  Succeeded,
  Failed
}

type InviteFormProps = {
  open: boolean;
  handleClose: any;
};

const InviteForm = ({ open, handleClose }: InviteFormProps) => {
  const [ chipState, setChipState] = useState("");
  const [emailItems, setEmailItems] = useState<string[]>([]);
  const [inviteStatus, setInviteStatus ] = useState<InviteRequestStatusType>(
    InviteRequestStatusType.Inactive
  );

  const [inviteCourses, setInviteCourses] = useState<string[]>([]);
  const [courses, setCourses] = useState<Course[]>([]); 

  const {isAuthenticated, getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    // Must be authenticated before requesting courses or we end up in a request loop
    if (isAuthenticated && courses.length === 0) {
        getAccessTokenSilently().then( (token) => {
          try {
              const addCourseToList = (coursedetails: Course) => {
                let bFound = false;
                for(let i=0;i<courses.length;i++){
                  if(courses[i].name === coursedetails.name) {
                    bFound = true;
                  }
                }
                if(!bFound){
                  setCourses((courses) => [...courses, coursedetails].sort((a,b) => {
                    if (a.name === b.name) {
                      return 0;
                    }
                    return a.name > b.name ? 1:-1;
                  }));
                }
              };

              axios
                .get(`${process.env.REACT_APP_FLIGHTDECK_API}/`, {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                })
                .then(response => {
                  // Get details for every course
                  response.data.map( (val: string)  => { 
                    axios
                      .get(`${process.env.REACT_APP_FLIGHTDECK_API}/${val}/course.yaml`  , {
                        headers: {
                          Authorization: `Bearer ${token}`
                        }
                      })
                      .then(response => {
                        let res = YAML.parse(response.data);     
                        addCourseToList(res);
                      });
                      return true;
                  });
                });
            } catch (error: any) {
              console.log(error);
            }
        });
    }
  }, [isAuthenticated, getAccessTokenSilently, courses])

  // CHIP LOGIC
  const addEmailAddress = (email: string) => {
    let email_list = email.split(" ");
    addEmailAddresses(email_list);
  };

  const addEmailAddresses = (emails: string[]) => {
    const validEmails: string[] = emails.filter(isEmailAddressValid);
    setEmailItems([...emailItems, ...validEmails]);
  };

  const deleteEmailAddress = (email: string) => {
    const newEmailList: string[] = emailItems.filter(obj => obj !== email);
    setEmailItems(newEmailList);
  };

  const isEmailAddressValid = (email: string) => {
    let error: string | null = null;
    if (isInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      setChipState(error);
      return false;
    }
    setChipState("");
    return true;
  };
  
  const isInList = (email: string) => {
    return emailItems.includes(email);
  };
  const isEmail = (email: string) => {
    return /[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/.test(email);
  };
  // END CHIP LOGIC

  const handleChangeMultiple = (event: React.ChangeEvent<HTMLInputElement>) => {
    const courseName: string = event.target.name;
    if (event.target.checked) {
      setInviteCourses([...inviteCourses, courseName]);
    } else {
      setInviteCourses(inviteCourses.filter(name => name !== courseName));
    }
  };

  const shortDelay = () => new Promise(resolve => setTimeout(resolve, 1200));

  // Send the actual request
  const sendInvitePostRequest = (
    authToken: string,
    statusUpdateCallback: Function
  ) =>
    new Promise(resolve => {
      return axios
        .post(
          `${process.env.REACT_APP_FLIGHTDECK_API}/invite?`,
          {
            emails: emailItems,
            courses: inviteCourses
          },
          {
            headers: { Authorization: `Bearer ${authToken}`, 'Content-Type':'application/json' }
          }
        )
        .then(response => {
          console.log(`Successfully sent invite ${response}`);
          statusUpdateCallback(InviteRequestStatusType.Succeeded);
        })
        .catch(error => {
          console.log(`Error sending invite POST request: ${error}`);
          toast.error("Error sending invite POST request");
          statusUpdateCallback(InviteRequestStatusType.Failed);
        })
        .finally(() => (resolve));
    });

  const handleInviteSubmit = () => {
    if (emailItems.length < 1 || inviteCourses.length < 1) {
      console.log("No Emails or Courses set");
      toast.error("No Emails or Courses set");
      return;
    }

    setInviteStatus(InviteRequestStatusType.Fetching);
    try{
      getAccessTokenSilently().then((token) => {
        sendInvitePostRequest(token, setInviteStatus)
      })
      .catch(error => {
        console.error(`cannot get token from auth server: ${error}`);
        setInviteStatus(InviteRequestStatusType.Failed);
        toast.error("Failed to get token from auth server");
      })
      .finally(() => {
        console.log("Delay begins");
      })
      .then(shortDelay)
      .then(() => {
        console.log("Delay finished");
        setInviteStatus(InviteRequestStatusType.Inactive);
        toast.success("Invitations Sent", {
          icon: '📨',
          style: {
            borderRadius: '5px',
            background: '#333',
            color: '#fff',
          },
        });
        return handleClose();
      });
    } catch(e) {
      console.log("Error submitting email");
      console.log(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Invite User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To invite a user to Flightdeck, type their email address here, and
          tick the course(s) you&lsquo;d like them to have access to.
        </DialogContentText>
         <MuiChipsInput  
              value={emailItems}
              helperText={chipState}
              onAddChip={ chp => addEmailAddress(chp)}
              onDeleteChip={(chip, index) => deleteEmailAddress(chip)}
              margin="dense"
              id="email"
              label="Email Address"
              fullWidth  
            />
        <FormControl component="fieldset">
          <FormGroup>
            {courses.map((course: Course, index: number) => (
              <FormControlLabel
                key={course.name + index.toString()}
                control={
                  <Checkbox
                    onChange={handleChangeMultiple}
                    name={course.name}
                  />
                }
                label={course.displayName}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {inviteStatus === InviteRequestStatusType.Fetching && (
          <CircularProgress size="1em" />
        )}
        {inviteStatus === InviteRequestStatusType.Succeeded && (
          <CheckCircleIcon style={{ color: green[500] }} />
        )}
        {inviteStatus === InviteRequestStatusType.Failed && (
          <ErrorIcon style={{ color: red[500] }} />
        )}
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleInviteSubmit} color="primary">
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteForm;
