/* eslint-disable */
import {Workshop} from "./Workshop";

export type Course = {
    name: string;
    displayName: string;
    description: string;
    difficulty: Difficulty;
    workshops: (Workshop[]);
};

export enum Difficulty {
    Beginner = "Beginner",
    Intermediate = "Intermediate",
    Advanced = "Advanced"
}

export enum CourseFetchState {
    "NotFetchedYet",
    "LoadingNoContent",
    "LoadingWithContent",
    "FinishedLoadingNoContent",
    "FinishedLoadingWithContent",
    "ErrorFetchingContent"
}

export enum WorkshopKind {
    Workshop = "workshop"
}

export type Organization = {
    metadata: {
        name: string;
    };
    spec: {
        expiryTime?: string;
    };
};

export enum DifficultySort {
    Beginner = 0,
    Intermediate = 1,
    Advanced = 2
}

export type Account = {
    metadata: {
        name: string;
    };
    spec: {
        superAdmin: boolean;
    };
    status: {
        organizations: string[];
    };
};

export type User = {
    metadata: {
        name: string;
        organization: string;
    };
    spec: { fullName: string };
};
