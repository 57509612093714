import React from "react";
import {Link} from "react-router-dom";
import {Difficulty, WorkshopKind} from "../../typings";
import {COURSES_PATH} from "../../constants";

import "./WorkshopCard.css";

type WorkshopCardProps = {
    id: number;
    name: string;
    description: string;
    difficulty: Difficulty;
    type: WorkshopKind;
    courseId: string;
};

const WorkshopCard = ({
                          id,
                          name,
                          description,
                          courseId
                      }: WorkshopCardProps) => (
    <Link
        to={`${COURSES_PATH}/${courseId}/${id}`}
        className="WorkshopCard"
    >
        <article key={id} className="WorkshopCard__article">
            <div>
                <h4 className="WorkshopCard__title">{name}</h4>
            </div>
            <div>
                <p>{description}</p>
            </div>
        </article>
    </Link>
);

export default WorkshopCard;
