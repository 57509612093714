import React from "react";
import {Link, Route} from "react-router-dom";
import Card from "@material-ui/core/Card";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";

import {COURSES_PATH} from "../../constants";
import Page from "../Page";
import CourseDetail from "../CourseDetail";

import "./Courses.css";
import LoadingMessage from "../LoadingMessage";
import ErrorMessage from "../ErrorMessage";
import {Course} from "../../typings";
import {useQueries, useQuery, UseQueryResult} from "react-query";
import {getAndParseCourses, courseDetailQueries} from "../../queries";

const Courses = () => {
    const courses = useQuery(['courses'], getAndParseCourses);
    const courseList = courses?.data?.sort() || [];
    const courseDetails: UseQueryResult<Course, unknown>[] = useQueries(courseDetailQueries(courseList)) as UseQueryResult<Course, unknown>[];

    return (
        <Page title="Courses" className="Courses__page">
            <Route exact path={COURSES_PATH} component={CourseElement}/>
            <Route exact path={`${COURSES_PATH}/:courseId`}>
                <CourseDetail />
            </Route>
        </Page>
    );

    function CourseElement() {
        switch (courses.status) {
            case "loading":
                return <LoadingMessage label="Loading courses"/>;
            case "error":
                return <ErrorMessage message={"Error fetching courses"}/>;
        }
        return (
            <div className="Courses__main">
                <h1 className="Courses__title"><LibraryBooksOutlinedIcon/>Courses</h1>
                <p className="Courses__intro">Choose a course to get started</p>
                <div className="Courses__grid">
                    {
                        courseDetails.map((res: UseQueryResult<Course, unknown>, index: number) => {
                            switch (res.status) {
                                case "loading":
                                    return <LoadingMessage key={"Course_map_" + index.toString()} label="Loading course details"/>;
                                case "error":
                                    return <ErrorMessage key={"Course_map_" + index.toString()} message="Error fetching course details"/>;
                            }
                            return (
                                <Link
                                    className="Courses__link"
                                    key={res.data?.name}
                                    to={`${COURSES_PATH}/${res.data?.name}`}
                                >
                                    <Card className="Courses__card" key={"course_link_card_" + index.toString()}>
                                        <h2>
                                            {res.data?.displayName ||
                                                res.data?.name}
                                        </h2>
                                        <p>{res.data?.description}</p>
                                        {res.data?.difficulty && (
                                            <p className="Courses__difficulty">
                                                Difficulty: <strong>{res.data?.difficulty}</strong>
                                            </p>
                                        )}
                                    </Card>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
};


export default Courses;
