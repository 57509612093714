import { useEffect } from "react";

const useScrollToTopOnMount = (...conditions: any[]) => {
  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    conditions ? conditions : [] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export default useScrollToTopOnMount;
