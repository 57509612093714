import React from "react";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

type ToolbarNavItemProps = {
  path?: string;
  Icon: React.ComponentType;
  text: string;
  onClick?: Function;
  exact?: boolean;
};

const ToolbarNavItem = ({
  path,
  Icon,
  text,
  onClick,
  exact
}: ToolbarNavItemProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <li>
      {path ? (
        <Tooltip placement="right" title={text}>
          <span>
            <NavLink
              to={path}
              className="Toolbar__navItem"
              activeClassName="Toolbar__navItem--active"
              onClick={handleClick}
              exact={exact}
            >
              <span>
                <span className="Toolbar__navItemIcon">
                  <Icon />
                </span>
              </span>
            </NavLink>
          </span>
        </Tooltip>
      ) : (
        <Tooltip placement="right" title={text}>
          <button className="Toolbar__navItem" onClick={handleClick}>
            <span className="Toolbar__navItemIcon">
              <Icon />
            </span>
          </button>
        </Tooltip>
      )}
    </li>
  );
};

export default ToolbarNavItem;
