import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";

import "./UserMenu.css";
import { useAuth0 } from "@auth0/auth0-react";
// import {getUserExpiry} from "../../utils/userSelectors";

const UserMenu = () => {
  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const expiry = getUserExpiry();

  // const expiryDateHuman = (expiry && expiry.expiryDateHuman) || "";

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    setAnchorEl(null);
    logout({ returnTo: window.location.origin });
  };

  const SignedInMenu = () => (
      <div>
        <div className="UserMenu__definitionsItem">
          <dt>Account:</dt>
          <dd>{user!.name}</dd>
        </div>
        {/* <div className="UserMenu__definitionsItem">
          <dt>Expiry date:</dt>
          <dd>{expiryDateHuman!}</dd>
        </div> */}
        <Divider />
        <MenuList>
          <MenuItem onClick={handleSignout}>Sign out</MenuItem>
        </MenuList>
      </div>
  );

  const SignedOutMenu = () => (
    <div>
      <MenuList>
        <MenuItem onClick={loginWithRedirect}>Sign in</MenuItem>
      </MenuList>
    </div>
  );

  return (
    <div>
      <IconButton
        id="user-menu"
        aria-controls="user-menu"
        aria-haspopup="true"
        className="Toolbar__logout"
        onClick={handleClick}
      >
        <PersonIcon />
      </IconButton>
      <Popover
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        className="UserMenu"
      >
      <dl className="UserMenu__definitions">
        {isAuthenticated ? < SignedInMenu /> : < SignedOutMenu />}
      </dl>
      </Popover>
    </div>
  );
};
export default UserMenu;
