import React, {useEffect, useRef, useState} from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { Base64 } from 'js-base64';
import { copy } from "clipboard";
import toast from 'react-hot-toast';
import "./MarkdownRenderer.css";
import escapeCode from "./escapeCodeUtil";
import hljs from "highlight.js";

const openLabel = "Hide";
const defaultClosedLabel = "Reveal expected output";
const renderer = new marked.Renderer();

renderer.code = (code, infostring = "", escaped) => {
    // Break out code_type|extra_information| from code annotation
    // eg. ```bash|copy|   or    ```output|Alternative Button Name|
    const components = infostring !== null && infostring.split("|");
    const lang = components && components.length > 0 ? components[0] : null;
    const promptText = components && components.length > 1 ? components[1] : null;

    const includeCopy = (promptText === 'copy');
    const closedLabel = (lang === 'output' && promptText !== null) ? promptText : defaultClosedLabel;
 
    const outputCode = escaped ? code : escapeCode(code, true);

    if (!lang) {
        return `<pre><code>${outputCode}</code></pre>`;
    }

    const escapedLang = escapeCode(lang, true);

    if (lang === "output") {
        return `<button class="MarkdownRenderer__collapsibleTrigger" data-is-open="false" 
                    data-collapsible-trigger type="button" data-label-name="${closedLabel}">${closedLabel}</button>
                    <pre class="MarkdownRenderer__output"><code class="language-${escapedLang}">${outputCode}</code></pre>\n`;
    }else if (lang === "console") {
        return "<div class='markdown-console-window' style='margin-bottom: 30px;'>" +
           "<pre style='margin-bottom: 0px;'>" +
           "<code class='language-text'>" + 
            outputCode + 
            "</code>" + 
            "</pre>" + 
           "</div>\n";
    }else if (lang === "information") {
        const encodedString = Base64.encode(code);
        return "<div class='information_button' data-content='" 
            + encodedString 
            + "'>" 
            + "ℹ"
            + "</div>";
    }

    let copyButton = "";
    if(includeCopy){
        const encodedString = Base64.encode(code);
        copyButton =  "<span class='copy-button' data-content='" + encodedString + "'>Copy this text</span>";
    }

    return "<div style='margin-bottom: 30px;'>" +
           "<pre style='margin-bottom: 0px;'>" +
           "<code class='language-" + escapedLang +"'>" + outputCode + "</code>" +
           "</pre>" +
           copyButton +
           "</div>\n";
};

type MarkdownRendererProps = {
    markdown: string;
};


const MarkdownRenderer = ({markdown}: MarkdownRendererProps) => {
    const html = marked.parse(markdown, {renderer});
    const cleanHtml = DOMPurify.sanitize(html);
    const markdowRef = useRef<HTMLDivElement | null>(null);

    const [informationContent, setInformationContent] = useState("");
    const [informationOffset, setInformationOffset] = useState({x:500, y: 20});   
    const [open, setOpen] = useState(false);

    const handleCollapsibleButtonClick = (event: Event) => {
        event.preventDefault();
        const target =
            event.currentTarget && (event.currentTarget as HTMLButtonElement);

        if (!target) return;

        const isOpen = target.getAttribute("data-is-open");

        if (isOpen === "true") {
            target.setAttribute("data-is-open", "false");
            const label = target.getAttribute('data-label-name');
            target.innerText = label ? label : defaultClosedLabel;
        } else {
            target.setAttribute("data-is-open", "true");
            target.innerText = openLabel;
        }
    };

    const handleCopyTextButtonClicked = (event: Event) => {
        event.preventDefault();
        const target = event.currentTarget && (event.currentTarget as HTMLSpanElement);
        if (!target) {
            return;
        }

        const content = target.getAttribute('data-content');
        if (content === null){
            return;
        }
        const decoded = Base64.decode(content);
        copy(decoded); // Copy to clipboard
        toast.success("Copied to clipboard", {
            icon: '📋',
            style: {
              borderRadius: '5px',
              background: '#333',
              color: '#fff',
            },
        });
    }

    const mouseoverInformationButton = (evt: Event) => {
        evt.preventDefault();
        
        const target = evt.currentTarget && (evt.currentTarget as HTMLSpanElement);
        if (!target) {
            return;
        }
        
        const content = target.getAttribute('data-content');
        if (content === null){
            return;
        }
        const event = evt && (evt as MouseEvent);
        if (!event) {
            return;
        }
        // Prepare the information to go in the hover box
        const decoded = Base64.decode(content);
        setInformationOffset({x: event.pageX + 20, y: event.pageY});
        setOpen(true);
        setInformationContent(decoded.replaceAll('\n', "<br>"));
    };

    const mouseoutInformationButton = (event: Event) => {
        // Hide information box
        setOpen(false);
    };

    useEffect(() => {
        if (markdowRef && markdowRef.current) {
            // Highlight the code blocks on each render of the markdown
            const preblocks = markdowRef.current.querySelectorAll("pre code[class]");
            preblocks.forEach(block => {
                let htmlBlock = block as HTMLElement;
                hljs.highlightElement(htmlBlock);
            });


            // Add handlers for copy button
            const copyButtons = markdowRef.current.querySelectorAll(
                ".copy-button"
            );
            if (copyButtons.length) {
                copyButtons.forEach(span => {
                    span.addEventListener('click', handleCopyTextButtonClicked);
                });
            }
            
            const infoButtons = markdowRef.current.querySelectorAll(".information_button");
            if (infoButtons.length) {
                infoButtons.forEach(span => {
                    span.addEventListener('mouseover', mouseoverInformationButton);
                })
                infoButtons.forEach(span => {
                    span.addEventListener('mouseout', mouseoutInformationButton);
                })
            }

            const links = markdowRef.current.querySelectorAll('a');
            if (links.length){
                links.forEach(link => {
                    link.setAttribute("target", "_blank");
                })
            }

            const collapsibleTriggerButtons = markdowRef.current.querySelectorAll(
                "button[data-collapsible-trigger]"
            );
            if (collapsibleTriggerButtons.length) {
                collapsibleTriggerButtons.forEach(button => {
                    button.addEventListener("click", handleCollapsibleButtonClick);
                });

                return () => {
                    collapsibleTriggerButtons.forEach(button => {
                        button.removeEventListener("click", handleCollapsibleButtonClick);
                    });
                };
            }
        }
    }, [markdown]);

    const getInformationPopup = () => {
        if(open){
            return <div 
                        className='information_popup' 
                        style={{ "left": informationOffset.x + "px" ,"top": informationOffset.y + "px"}}
                        dangerouslySetInnerHTML={{__html: informationContent}}
                    />;
        }else{
            return <></>;
        }
    }

    /*
      dangerouslySetInnerHTML is called such to warn developers that what they're doing could
      potentially lead to Cross Site Scripting (XSS). https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml

      Running the content through DOMPurify prevents XSS
    */
    return (
        <>
        <div
            ref={markdowRef}
            className="MarkdownRenderer"
            dangerouslySetInnerHTML={{__html: cleanHtml}}
        />
         {getInformationPopup()}
        </>
    );
};

MarkdownRenderer.defaultProps = {
    markdown: ""
};

export default MarkdownRenderer;
