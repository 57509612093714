import React from "react";
import Grid from "@material-ui/core/Grid";
import { useAuth0 } from '@auth0/auth0-react';

import Page from "../Page";
import Button from "../Button";
import Logo from "./Logo";
import "./Login.css";

const Login = () => {
  // eslint-disable-next-line
  const {isAuthenticated,loginWithRedirect,} = useAuth0();
  
  return (
    <Page title="Login" className="Login">
      <Grid item xs={3} className="Login__left">
        <h1 className="Login__title">Welcome to the Flightdeck</h1>
        <p>
          Build in-house Kubernetes expertise with{" "}
          <a href="https://jetstack.io">Jetstack</a> on-demand training
        </p>
        <ul>
          <li>
            Self-paced beginner, intermediate and advanced training for
            operators and developers
          </li>
          <li>
            Test your operational proficiency with interactive wargaming
            workshops
          </li>
          <li>
            On-call operational playbooks, providing troubleshooting steps &
            tips
          </li>
        </ul>
        <div className="Login__button">
          <Button onClick={loginWithRedirect}>Sign-in to Flightdeck</Button>
        </div>
      </Grid>
      <Grid item xs={9} className="Login__right">
        <Logo />
      </Grid>
    </Page>
  );
};

export default Login;
