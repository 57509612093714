import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import {COURSES_PATH, HELP_PATH, HOME_PATH,} from "../../constants";
import ToolbarNavItem from "./ToolbarNavItem";
import UserMenu from "../UserMenu";
// import ExpiryWarning from "../ExpiryWarning";

import logo from "./jetstack-logo-negative.svg";
import "./Toolbar.css";
import InviteForm from "../InviteForm";
import {isAdmin} from "../../utils/adminSelectors";
import { useAuth0 } from "@auth0/auth0-react";

declare global {
    // eslint-disable-next-line
    interface Window {
        FreshworksWidget: any;
    }
}

const Toolbar = () => {
    const {isAuthenticated, user} = useAuth0();
    const [isUserAdmin, setIsUserAdmin] = useState(false); 

    useEffect(() => {
        if(isAuthenticated){
            setIsUserAdmin(isAdmin(user));
        }
    }, [user, isAuthenticated]);

    const [inviteOpen, setInviteOpen] = React.useState(false);

    const handleInviteClickOpen = () => {
      setInviteOpen(true);
    };

    const handleInviteClose = () => {
        setInviteOpen(false);
    };

    return (
        <div className="Toolbar">

            { isUserAdmin &&
              <InviteForm open={inviteOpen} handleClose={handleInviteClose}/>
            }

            <Link to={HOME_PATH} className="Toolbar__logo">
                <img src={logo} alt=""/>
            </Link>

            <nav>
                <ul>
                    <ToolbarNavItem
                        path={HOME_PATH}
                        Icon={HomeOutlinedIcon}
                        text="Home"
                        exact
                    />

                    {isAuthenticated && (
                        <ToolbarNavItem
                            path={COURSES_PATH}
                            Icon={LibraryBooksOutlinedIcon}
                            text="Courses"
                        />
                    )}

                    <ToolbarNavItem
                        path={HELP_PATH}
                        Icon={HelpOutlineOutlinedIcon}
                        text="Help"
                    />

                    {isUserAdmin && (
                    <ToolbarNavItem
                        Icon={MailOutlinedIcon}
                        text="Invite User"
                        onClick={handleInviteClickOpen}
                    />
                    )}

                </ul>
            </nav>

            {isAuthenticated && (
                <div className="Toolbar__bottom">
                    <div className="Toolbar__bottomContainer">
                        {/* <ExpiryWarning/> */}
                        <UserMenu/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Toolbar;
