type Replacements = {
  [key: string]: string;
};

// Helper taken from https://github.com/markedjs/marked/blob/ca3741fc868ff3b8441ce3e495291ce9557d68fd/lib/marked.js#L1347
export default function escapeCode(html: string, encode: boolean = false) {
  const escapeTest = /[&<>"']/;
  const escapeReplace = /[&<>"']/g;
  const replacements: Replacements = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;"
  };

  const escapeTestNoEncode = /[<>"']|&(?!#?\w+;)/;
  const escapeReplaceNoEncode = /[<>"']|&(?!#?\w+;)/g;

  if (encode) {
    if (escapeTest.test(html)) {
      return html.replace(escapeReplace, function(ch: string): string {
        return replacements[ch];
      });
    }
  } else {
    if (escapeTestNoEncode.test(html)) {
      return html.replace(escapeReplaceNoEncode, function(ch: string): string {
        return replacements[ch];
      });
    }
  }

  return html;
}
