import React from "react";
import Login from "../components/Login";
import LoadingMessage from "../components/LoadingMessage";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorMessage from "../components/ErrorMessage";

function withAuth<WrappedProps extends object>(
    Component: React.ComponentType<WrappedProps>
) {
    function MakeLogin(props: WrappedProps) {

        const {
            isLoading,
            isAuthenticated,
            error,
        } = useAuth0();
        if (error) {
            return <ErrorMessage message={error.message} />
        }
        if (isLoading) {
            return <LoadingMessage label="LOADING..."/>
        }
        if (isAuthenticated) {
            return <Component {...(props as WrappedProps)} />
        }

        return <Login/>
    }

    return MakeLogin;
}

export default withAuth;
