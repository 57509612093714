import React from "react";
import "./ErrorPage.css";
import { Base64 } from 'js-base64';

export const GENERIC_ERROR_MESSAGE = "Please contact the site administrator if this problem persists";

const ErrorPage = () => {
    const searchParams = new URLSearchParams(document.location.search)
    const msg_encoded = searchParams.get("msg");
    let msg = "";
    if (msg_encoded) {
        try {
            msg = Base64.decode(msg_encoded);
            // Taken from https://infinitbility.com/how-to-remove-html-tags-from-string-in-react-js/
            // Removes all potentially unsafe HTML
            msg = msg.replace(/(<([^>]+)>)/ig, '');
            msg = msg.replaceAll("\n", "<br>");
            if (msg.trim() === "") {
                msg = GENERIC_ERROR_MESSAGE;
            }
        } catch(e) {
            console.log("An error occurred while decodiong");
            console.log(e);
            msg = GENERIC_ERROR_MESSAGE;
        }   
    } else {
        msg = GENERIC_ERROR_MESSAGE;
    }

    return <div className="error_message">
            <h1>Something Went Wrong</h1>             
            <h3 dangerouslySetInnerHTML={{__html: msg}}></h3>
        </div>
};

export default ErrorPage;
