import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./LoadingMessage.css";

type LoadingMessageProps = {
  label: string;
};

const LoadingMessage = ({ label }: LoadingMessageProps) => (
  <div className="LoadingMessage">
    <CircularProgress />
    <span className="LoadingMessage__text">{label}</span>
  </div>
);

export default LoadingMessage;
