import React from "react";

import "./ErrorMessage.css";

type ErrorMessageProps = {
  message: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <div className="ErrorMessage">
    <p>{message}</p>
  </div>
);

export default ErrorMessage;
