import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NotFound from "../NotFound";
import WorkshopsList from "../WorkshopList";
import LoadingMessage from "../LoadingMessage";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";

import "./CourseDetail.css";
import {COURSES_PATH} from "../../constants";
import AssetDownloadButton from "../AssetDownloadButton";
import {useHistory, useParams} from "react-router";
import {useQuery} from "react-query";
import {Course} from "../../typings";
import {getAndParseCourseDetails} from "../../queries";

const CourseDetail = () => {
    let { courseId } : any = useParams();
    let history = useHistory();
    useScrollToTopOnMount();

    const {isLoading, error, data} = useQuery(["course", courseId], () => getAndParseCourseDetails(courseId))
    const course = data as Course;

    const handleBackClick = () => {
        // @ts-ignore
        history.push(COURSES_PATH);
    };

    const renderMainBodyContent = () => {
        if (isLoading) {
            return <LoadingMessage label="Loading course details"/>;
        }
        if (error) {
            return <NotFound/>;
        }

        return (
            <>
                <h2 className="CourseDetail__title">
                    {course.displayName || course.name}
                </h2>
                <div className="CourseDetail__description">
                    <p>{course.description}</p>
                </div>
                <div><p>&nbsp;</p></div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AssetDownloadButton assetPath={`${courseId}/slides.pdf`}
                                             newFileName={`${course.displayName} - Slides.pdf`}>
                            Download Slides</AssetDownloadButton>
                    </Grid>
                    <Grid item xs={12}>
                        <AssetDownloadButton assetPath={`${courseId}/manifests.zip`}
                                             newFileName={`${course.displayName} - Attendee Resources.zip`}>
                            Download Attendee Resources</AssetDownloadButton>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <Grid item md={4} className="CourseDetail">
                <div className="CourseDetail__back">
                    <Button size="small" onClick={handleBackClick}>
                        <ArrowBackIcon/>
                        <span className="CourseDetail__backText">Back to courses</span>
                    </Button>
                </div>
                {renderMainBodyContent()}
            </Grid>
            <Grid item md={8} className="CourseDetail__workshops">
                <WorkshopsList courseId={courseId}/>
            </Grid>
        </>
    );
};

export default CourseDetail;
