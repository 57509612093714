import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import { useAuth0 } from "@auth0/auth0-react";

import LoadingMessage from "../LoadingMessage";

import { COURSES_PATH } from "../../constants"

import "./InviteHandler.css";

// This component initiates an Auth0 sign-in on load. This is a workaround, because on the initial callback from
// an Auth0 passwordless login, the user's access token is not present. There may be a neater way to do this in future.
const InviteHandler = () => {
    const dispatch = useDispatch();
    const {isAuthenticated} = useAuth0();
    useEffect(() => {
      if (isAuthenticated) {
        dispatch(push(COURSES_PATH))
      }
    }, [dispatch, isAuthenticated])


    return (
        <div className="InviteHandler">
            <LoadingMessage label="Checking authentication"/>
        </div>
    );
};

export default InviteHandler;
