import React from "react";
import Grid from "@material-ui/core/Grid";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import "./Page.css";

type PageProps = {
    title: string;
    children: any;
    className?: string;
};

const Page = ({children, title, className}: PageProps) => {
    useDocumentTitle(title);

    return (
        <Grid container spacing={0} className={`Page ${className}`}>
            {children}
        </Grid>
    );
};

export default Page;
