import React, { useState } from "react";

import Button from "../Button";
import ErrorMessage from "../ErrorMessage";

import { useAuth0 } from "@auth0/auth0-react";

import axios from "axios";

type ButtonProps = {
  assetPath: string;
  newFileName: string;
  children: any;
};

// downloads assets from the assets path
const AssetDownloadButton = ({
  children,
  assetPath,
  newFileName
}: ButtonProps) => {
  const [isDownloading, setIsDownloading]= useState(false);
  const {getAccessTokenSilently } = useAuth0();

  const handleDownloadManifest = () => {
    try {
      if (assetPath === "" || newFileName === "") {
        throw ErrorMessage({ message: "missing request parameters" });
      }

      if(isDownloading){
        return;
      }
      setIsDownloading(true);

      getAccessTokenSilently().then(token => {
        axios
          .get(`${process.env.REACT_APP_FLIGHTDECK_API}/` + assetPath, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", newFileName);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
          });
      });
    } catch (error: any) {
      setIsDownloading(false);
      return (
        <ErrorMessage message={`Error downloading assets: ${error.message}`} />
      );
    }
  };  

  return <Button onClick={handleDownloadManifest} disabled={isDownloading}>{children}</Button>;
};

export default AssetDownloadButton;
