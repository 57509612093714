import React, {useState} from "react";

import NotFound from "../NotFound";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";

import "./Workshop.css";
import WorkshopCollapsibleArea from "../WorkshopCollapsibleArea";
import MarkdownRenderer from "../MarkdownRenderer";
import parseWorkshopRelativeLinks from "../../utils/parseWorkshopRelativeLinks";
import WorkshopNav from "../WorkshopNav";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {useQuery} from "react-query";
import {Course} from "../../typings";
import {getAndParseCourseDetails} from "../../queries";


type WorkshopProps = {
    courseId: string;
    workshopId: string;
};

const Workshop = ({ courseId, workshopId }: WorkshopProps) => {
    const theme = useTheme();
    const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const workshopNdx: number = (parseInt(workshopId));
    const [workshopDescriptionOpen, setWorkshopDescriptionOpen] = useState(true);

    // eslint-disable-next-line
    const {isLoading, error, data} = useQuery(["course", courseId], () => getAndParseCourseDetails(courseId))
    const workshop = data?.workshops[workshopNdx];
    const course = data as Course;

    useScrollToTopOnMount(workshopNdx);

    if (!workshop) {
        return <NotFound/>;
    }

    return (
        <>
            {mediumScreen && <WorkshopNav workshopNdx={workshopNdx} course={course} showToggle={largeScreen} toggleAction={() => (setWorkshopDescriptionOpen(x => !x))}/>}
            {workshopDescriptionOpen && <WorkshopCollapsibleArea workshop={workshop}/>}
            <div className="Workshop">
                <div className="Workshop__main">
                    {!workshopDescriptionOpen && (
                        <h2 className="Workshop__title">
                            {workshop.displayName}
                        </h2>
                    )}
                    <MarkdownRenderer markdown={parseWorkshopRelativeLinks(workshop.content)} />
                </div>
            </div>
        </>
    );
};

export default Workshop;
