import React from "react";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";

import "./NotFound.css";

const NotFound = () => (
  <div className="NotFound">
    <div className="NotFound__icon">
      <NotListedLocationIcon fontSize="inherit" />
    </div>
    <p>404 page not found</p>
  </div>
);

export default NotFound;
