import React from "react";
import WorkshopCard from "../WorkshopCard";
import LoadingMessage from "../LoadingMessage";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";

import "./WorkshopList.css";
import {Course, WorkshopKind} from "../../typings";
import {useQuery} from "react-query";
import {getAndParseCourseDetails} from "../../queries";

type WorkshopsListProps = {
  courseId: string;
};

const WorkshopsList = ({ courseId }: WorkshopsListProps) => {
  const {isLoading, error, data} = useQuery(["course", courseId], () => getAndParseCourseDetails(courseId))
  const course = data as Course;
  const workshops = course?.workshops;

  useScrollToTopOnMount();

  const renderContent = () => {
    if (isLoading) {
      return <LoadingMessage label="Loading course contents" />;
    }

    if (error) {
      return <p>Error</p>;
    }

    if (!course.workshops.length) {
      return <p>No workshops</p>;
    }

    return (
      <div>
        <h3 className="WorkshopsList__heading">
          Showing {workshops.length.toString()} workshops
        </h3>
        {workshops.map((workshop, ndx) => {
          return (
            <WorkshopCard
              key={ndx}
              id={ndx}
              type={WorkshopKind.Workshop}
              name={workshop.displayName}
              description={workshop.description}
              difficulty={workshop.difficulty}
              courseId={courseId}
            />
          );
        })}
      </div>
    );
  };

  return <div className="WorkshopsList">{renderContent()}</div>;
};

export default WorkshopsList;
