import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {QueryClient, QueryClientProvider} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'


import Toolbar from "./components/Toolbar";

import Home from "./components/Home";
import Courses from "./components/Courses";
import Help from "./components/Help";
import NotFound from "./components/NotFound";

import {
  AUTH_CALLBACK_PATH,
  COURSES_PATH,
  HELP_PATH,
  HOME_PATH,
  ERROR_PATH
} from "./constants";

import "./App.css";
import Flashes from "./components/Flashes";
import Workshop from "./components/Workshop";
import ErrorPage from "./components/ErrorPage";

import OfflineWarning from "./components/OfflineWarning";
import InviteHandler from "./components/InviteHandler";
import withAuth from "./hoc/withAuth";
import { useAuth0 } from "@auth0/auth0-react";
import {sec} from "./security";
import { useParams } from "react-router";

export const App: React.FC = () => {
  const {  getAccessTokenSilently} = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);

  const CoursesWithAuth = withAuth(Courses);
  const HomeWithAuth = withAuth(Home);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      }
    }
  });


  return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={true} />
        <div className="App">
          <Toolbar />
          <Flashes />
          <OfflineWarning />
          <Switch>
            {/* Routes that require authentication */}
            <Route exact path={`${COURSES_PATH}/:courseId/:workshopId`}>
              <WorkshopContainer />
            </Route>
            <Route path={COURSES_PATH}>
              <CoursesWithAuth/>
            </Route>
            <Route path={ERROR_PATH}>
              <ErrorPage />  
            </Route>            
            <Route path={HOME_PATH}>
              <HomeWithAuth/>
            </Route>

            {/* Public routes */}
            <Route path={HELP_PATH}>
              <Help/>
            </Route>
            <Route path={"/invite"}>
              <InviteHandler/>
            </Route>
            <Route>
              <NotFound/>
            </Route>
            <Redirect from={AUTH_CALLBACK_PATH} to={HOME_PATH} />
          </Switch>
        </div>
      </QueryClientProvider>
  );
};

type WorkshopParams = {
  courseId: string;
  workshopId: string;
}

const WorkshopContainer = () => {
  let { courseId, workshopId } = useParams<WorkshopParams>();
  const WorkshopsWithAuth = withAuth(Workshop);
  return (
    <WorkshopsWithAuth courseId={courseId} workshopId={workshopId}/>
  )
}

// Exports the Redux connected component
export default connect()(App);
